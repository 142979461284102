// eslint-disable-next-line import/no-cycle
import requestService from "../requestService";

const prefix = "/boards";

export default {
  async addBoard(uuid, payload) {
    const response = await requestService.post(
      `/projects/${uuid}${prefix}`,
      payload,
      {},
      false
    );
    return response?.data?.data?.object;
  },
  async getBoardsList(uuid) {
    const response = await requestService.get(`/projects/${uuid}${prefix}`);
    return response?.data?.data;
  },
  // Start MoveTasksList
  async MoveTasksList(projectId, boardId, listId, payload) {
    const response = await requestService.post(
      `/projects/${projectId}${prefix}/${boardId}/lists/${listId}/move-cards`,
      payload,
      {},
      false
    );
    return response?.data;
  },
  // End MoveTasksList
  async getBoardInfo(projectUuid, boardUuid) {
    const response = await requestService.get(
      `/projects/${projectUuid}/boards/${boardUuid}`
    );
    return response?.data?.data;
  },
  async deleteBoard(projectUuid, boardUuid) {
    const response = await requestService.delete(
      `/projects/${projectUuid}${prefix}/${boardUuid}`
    );
    return response?.data?.board;
  },
  async updateBoard(projectUuid, boardUuid, payload) {
    const response = await requestService.post(
      `/projects/${projectUuid}${prefix}/${boardUuid}/update`,
      payload,
      {},
      false
    );
    return response?.data?.board;
  },
  // lists (columns)
  async addNewList(projectUuid, boardUuid, payload) {
    const response = await requestService.post(
      `/projects/${projectUuid}${prefix}/${boardUuid}/lists`,
      payload
    );
    return response?.data?.list;
  },
  async getLists(projectUuid, boardUuid) {
    const response = await requestService.get(
      `/projects/${projectUuid}${prefix}/${boardUuid}/lists`
    );
    return response?.data?.lists;
  },
  async deleteList(projectUuid, boardUuid, listUuid) {
    const response = await requestService.delete(
      `/projects/${projectUuid}${prefix}/${boardUuid}/lists/${listUuid}`
    );
    return response?.data;
  },
  async updateList(projectUuid, boardUuid, listUuid, payload) {
    const response = await requestService.post(
      `/projects/${projectUuid}${prefix}/${boardUuid}/lists/${listUuid}/update`,
      payload
    );
    return response?.data;
  },
  // Labels
  async addLabelToCard(projectUuid, boardUuid, listUuid, cardUuid, payload) {
    const response = await requestService.post(
      `/projects/${projectUuid}/boards/${boardUuid}/lists/${listUuid}/cards/${cardUuid}/add-label`,
      payload
    );
    return response?.data?.label;
  },
  async deleteLabelFromCard(
    projectUuid,
    boardUuid,
    listUuid,
    cardUuid,
    payload
  ) {
    const response = await requestService.post(
      `/projects/${projectUuid}/boards/${boardUuid}/lists/${listUuid}/cards/${cardUuid}/remove-label`,
      payload
    );
    return response?.data?.label;
  },
};
