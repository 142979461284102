// eslint-disable-next-line import/no-cycle
import requestService from "../requestService";

const prefix = "/cards";

export default {
  async addLablesCardCreate(projectUuid, boardUuid, listUuid, cardUuid, payload) {
    const response = await requestService.post(
      `/projects/${projectUuid}/boards/${boardUuid}/lists/${listUuid}${prefix}/${cardUuid}/add-labels`,
      payload
    );
    return response?.data?.card;
  },
  async createCard(projectUuid, boardUuid, listUuid, payload) {
    const response = await requestService.post(
      `/projects/${projectUuid}/boards/${boardUuid}/lists/${listUuid}${prefix}`,
      payload
    );
    return response?.data?.card;
  },
  async createCardMulti(projectUuid, payload) {
    const response = await requestService.post(
      `/projects/${projectUuid}${prefix}`,
      payload
    );
    console.log("res : ", response?.data);
    return response?.data;
  },
  async updateCard(projectUuid, boardUuid, listUuid, cardUuid, payload) {
    const response = await requestService.post(
      `/projects/${projectUuid}/boards/${boardUuid}/lists/${listUuid}${prefix}/${cardUuid}/update`,
      payload
    );
    return response?.data?.card;
  },
  async deleteCard(projectUuid, boardUuid, listUuid, cardUuid) {
    const response = await requestService.delete(
      `/projects/${projectUuid}/boards/${boardUuid}/lists/${listUuid}${prefix}/${cardUuid}`
    );
    return response?.data;
  },
  async getCards(projectUuid, boardUuid, listUuid, params = {}) {
    console.log(params);
    const response = await requestService.get(
      `/projects/${projectUuid}/boards/${boardUuid}/lists/${listUuid}${prefix}`,
      { ...params }
    );
    return {
      data: response?.data?.data,
      total: response?.data?.total,
    };
  },

 // Test cases
  async getAllTestCases(project, params) {
    const response = await requestService.get(
      `/projects/${project}/test-cases`, params 

    );
    return response?.data?.data;
  },
  async getTestCasesOnCard(project, boardUuid, listUuid, cardUuid, params) {
    const response = await requestService.get(
        `/projects/${project}/boards/${boardUuid}/lists/${listUuid}${prefix}/${cardUuid}/card-test-cases`, params
    );
    return response?.data;
  },
  async updateTestCaseInCard(project, boardUuid, listUuid, cardUuid, cardTestCaseId, payload) {
    const response = await requestService.post(
        `/projects/${project}/boards/${boardUuid}/lists/${listUuid}${prefix}/${cardUuid}/card-test-cases/${cardTestCaseId}`,
        payload
    );
    return response?.data?.data;
  },
  async addTestCaseToCard(project, boardUuid, listUuid, cardUuid, payload) {
    const response = await requestService.post(
        `/projects/${project}/boards/${boardUuid}/lists/${listUuid}${prefix}/${cardUuid}/card-test-cases`,
        payload
    );
    return response?.data?.data;
  },
  async deleteTestCaseFromCard(project, boardUuid, listUuid, cardUuid, cardTestCaseId) {
    const response = await requestService.delete(
      `/projects/${project}/boards/${boardUuid}/lists/${listUuid}${prefix}/${cardUuid}/card-test-cases/${cardTestCaseId}` , {}, false);
    console.log("response delete testcase :", response);
    return response?.data;
  },

  //archive
  async getBoardsArchive(project, board, params = {}) {
    console.log("Archives params = ", params);
    const response = await requestService.get(`/projects/${project}/boards/${board}/archive`,
      { ...params }
    );
    console.log("Archive response = ", response);
    return response?.data?.items;
  },

  async delBoardsArchive(project, board, objectId, params = {}) {
    console.log("Archives params = ", params);
    const response = await requestService.delete(`/projects/${project}/boards/${board}/force-delete/${objectId}`,
      { ...params }
    );
    console.log("Archive response = ", response);
    return response?.data?.items;
  },

  async unArchiveBoards(project, board, objectId, params = {}) {
    console.log("Archives params = ", params);
    const response = await requestService.patch(`/projects/${project}/boards/${board}/unarchive/${objectId}`,
      { ...params }
    );
    console.log("Archive response = ", response);
    return response?.data?.items;
  },

  async getBoardsActivity(projectUuid, boardUuid, params = {}) {
    const response = await requestService.get(
      `/projects/${projectUuid}/boards/${boardUuid}/activities`,
      { ...params }
    );
    return response?.data?.data;
  },
  async getCardsWithoutList(projectUuid, boardUuid, params = {}) {
    const response = await requestService.get(
      `/projects/${projectUuid}/boards/${boardUuid}/all-cards`,
      {
        ...params,
      }
    );
    return {
      data: response?.data?.data,
      total: response?.data?.total,
    };
  },
  async getAttachments(projectUuid, boardUuid, listUuid, cardUuid) {
    const response = await requestService.get(
      `/projects/${projectUuid}/boards/${boardUuid}/lists/${listUuid}${prefix}/${cardUuid}/attachment`
    );
    return response?.data?.attachments;
  },
  async addAttachment(projectUuid, boardUuid, listUuid, cardUuid, payload) {
    const response = await requestService.post(
      `/projects/${projectUuid}/boards/${boardUuid}/lists/${listUuid}${prefix}/${cardUuid}/attachment`,
      payload, {}, false
    );
    return response?.data?.attachment;
  },
  async deleteAttachment(
    projectUuid,
    boardUuid,
    listUuid,
    cardUuid,
    attachmentId
  ) {
    const response = await requestService.delete(
      `/projects/${projectUuid}/boards/${boardUuid}/lists/${listUuid}${prefix}/${cardUuid}/attachment/${attachmentId}/delete`
    );
    return response?.data;
  },
  async getTimeFromUser(project, params) {
    const response = await requestService.get(
      `/projects/${project}/time-records/user`, params
    );
    console.log("getTimeFromProject response time : ", response);
    return response?.data?.data;
  },
  async getTimeFromCard(projectUuid, boardUuid, listUuid, cardUuid) {
    const response = await requestService.get(
      `/projects/${projectUuid}/boards/${boardUuid}/lists/${listUuid}${prefix}/${cardUuid}/time-records`
    );
    return response?.data?.data;
  },
  async addUsedTime(project, payload) {
    const response = await requestService.post(
      `/projects/${project}/time-records`,
      payload,
      {},
      false
    );
    console.log("response time : ", response);
    return response?.data?.data;
  },
  async deleteTime(projectUuid, timeRecordId) {
    const response = await requestService.delete(
      `/projects/${projectUuid}/time-records/${timeRecordId}`
    );
    return response?.data;
  },

  async moveCard(projectUuid, boardUuid, cardUuid, payload) {
    const response = await requestService.post(
      `/projects/${projectUuid}/boards/${boardUuid}${prefix}/${cardUuid}/move`,
      payload
    );
    return response?.data?.card;
  },
  async addUserToCard(projectUuid, boardUuid, listUuid, cardUuid, payload) {
    const response = await requestService.post(
      `/projects/${projectUuid}/boards/${boardUuid}/lists/${listUuid}${prefix}/${cardUuid}/assign-user`,
      payload
    );
    return response?.data?.card;
  },
  async addUsersToCard(projectUuid, boardUuid, listUuid, cardUuid, payload) {
    const response = await requestService.post(
      `/projects/${projectUuid}/boards/${boardUuid}/lists/${listUuid}${prefix}/${cardUuid}/assign-users`,
      payload
    );
    return response?.data?.card;
  },
  async deleteUserFromCard(
    projectUuid,
    boardUuid,
    listUuid,
    cardUuid,
    payload
  ) {
    const response = await requestService.post(
      `/projects/${projectUuid}/boards/${boardUuid}/lists/${listUuid}${prefix}/${cardUuid}/remove-user`,
      payload
    );
    return response?.data?.card;
  },

  // Start code Get message active
  async GetmessageActive(
    projectUuid,
    boardUuid,
    listUuid,
    cardUuid,
    params = {}
  ) {
    const response = await requestService.get(
      `/projects/${projectUuid}/boards/${boardUuid}/lists/${listUuid}${prefix}/${cardUuid}/details`,
      { ...params }
    );
    return response.data;
  },
  // End code Get message active
  //
  // Start code Card change order
  async CardChangeOrder(projectUuid, boardUuid, listUuid, cardUuid, payload) {
    const response = await requestService.patch(
      `/projects/${projectUuid}/boards/${boardUuid}/lists/${listUuid}${prefix}/${cardUuid}/change-order`,
      payload
    );
    return response.data;
  },
  async listChangeOrder(projectUuid, boardUuid, listUuid, payload) {
    const response = await requestService.post(
      `/projects/${projectUuid}/boards/${boardUuid}/lists/${listUuid}/move`,
      payload
    );
    return response.data;
  },
  // End code Card change order

  // Start code Post message active
  async PostMessageActive(projectUuid, boardUuid, listUuid, cardUuid, payload) {
    const response = await requestService.post(
      `/projects/${projectUuid}/boards/${boardUuid}/lists/${listUuid}/cards/${cardUuid}/comments`,
      payload
    );
    return response?.data;
  },

  async DeleteMessageActive(
    projectUuid,
    boardUuid,
    listUuid,
    cardUuid,
    uuidComment
  ) {
    const response = await requestService.delete(
      `/projects/${projectUuid}/boards/${boardUuid}/lists/${listUuid}${prefix}/${cardUuid}/comments/${uuidComment}`
    );
    return response?.data;
  },
  async updateComment(
    projectUuid,
    boardUuid,
    listUuid,
    cardUuid,
    uuidComment,
    payload
  ) {
    const response = await requestService.put(
      `/projects/${projectUuid}/boards/${boardUuid}/lists/${listUuid}${prefix}/${cardUuid}/comments/${uuidComment}`,
      payload
    );
    return response?.data;
  },
  async changeCardCover(projectUuid, boardUuid, listUuid, cardUuid, payload) {
    const response = await requestService.patch(
      `/projects/${projectUuid}/boards/${boardUuid}/lists/${listUuid}${prefix}/${cardUuid}/change-cover`,
      payload
    );
    return response?.data;
  },
};
