<template>
  <v-dialog
    v-model="visibility"
    max-width="400"
    content-class="cross__dialog"
    :transition="$dialogTransition"
    @click:outside="onCancel()"
  >
    <v-card>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="orange"
        top
        absolute
      />
      <v-slide-x-transition>
        <div class="cross__inner">
          <v-btn fab small elevation="0" @click="onCancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <v-card-title class="justify-center">{{ $t("table.tags") }}</v-card-title>
      <v-divider class="mb-4 mx-4"></v-divider>
      <v-card-text class="pb-0 px-3">
        <v-row
          style="overflow-y: auto; overflow-x: hidden; max-height: 220px"
          v-if="labelsList.length"
          class="mx-1 mb-1"
        >
          <v-col
            v-for="label in labelsList"
            :key="label.id"
            cols="12"
            class="d-flex align-start pa-0"
          >
            <v-col
              cols="auto"
              class="pr-0 py-1 mb-2"
              v-if="location != 'settings'"
            >
              <v-checkbox
                hide-details
                @click.prevent="toggleStatus(label)"
                :input-value="label.is_assigned"
                v-model="label.is_assigned"
                color="orange"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="auto"
              style="flex-grow: 1"
              class="py-1 px-0"
              @click="toggleStatus(label, false)"
              ><t-tag
                :large="true"
                :tag="{ name: label.name, bg: label.color }"
              ></t-tag
            ></v-col>
            <v-col cols="auto" class="pl-1 pr-0 py-1">
              <v-btn
                v-if="location == 'settings'"
                icon
                small
                @click="onEdit(label)"
                :color="!$vuetify.theme.dark ? '#4B5262' : ' #fff'"
                ><v-icon size="20">mdi-pencil-outline</v-icon></v-btn
              >
            </v-col>
            <v-col cols="auto" class="px-0 py-1">
              <v-btn
                v-if="location == 'settings'"
                icon
                small
                @click="deleteLabel(label)"
                color="error"
                ><v-icon size="20">mdi-close</v-icon></v-btn
              >
            </v-col>
          </v-col>
        </v-row>
        <v-row v-else justify="center" class="mb-1">
          <v-col cols="auto">{{ $t("table.noData") }}</v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        ><v-btn
          v-if="location == 'settings'"
          block
          class="v-custom-tonal-btn--orange"
          elevation="0"
          outlined
          @click="onCreate()"
          ><v-icon size="18" class="mr-1">mdi-plus</v-icon
          >{{ $t("boards.create_new_tag") }}</v-btn
        ></v-card-actions
      >
    </v-card>
    <labelsDialog
      v-if="visibleDialog"
      :visible="visibleDialog"
      @close="visibleDialog = false"
      :label="selectedLabel"
      :type="dialogType"
      @create-label="createLabel"
      @update-label="updateLabel"
    />
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialogMixin";
import notifications from "@/mixins/notifications";
import labelsDialog from "./labelsDialog.vue";
import boardsService from "@/services/request/boards/boardsService";
import { mapGetters } from "vuex";
import * as getterTypes from "@/store/modules/sideBarValues/types/getters";
import Vue from "vue";
import projectsService from "@/services/request/projects/projectsService";

export default {
  mixins: [dialogMixin, notifications],
  data: () => ({
    selectedLabel: {},
    labels: {},
    labelsList: [],
    visibleDialog: false,
    dialogType: "",
    loading: false,
  }),
  props: {
    location: {
      required: false,
    },
    project: {
      required: false,
    },
    projectId: {
      required: true,
    },
    board: {
      required: false,
    },
    task: {
      required: false,
    },
    type: {
      required: false,
    },
  },
  components: {
    labelsDialog,
  },
  async mounted() {
    this.loading = true;

    await this.getLabels();
    this.labelsList = [...this.labels];
    if (this.location != "settings") {
      this.labelsList.forEach((label) => {
        label.is_assigned = this.task.assigned_labels.some(
          (item) => item.id === label.id
        );
      });
    }
    this.loading = false;
  },
  methods: {
    async getLabels() {
      try {
        this.labels = await projectsService.getLabels(
          this.projectId ? this.projectId : this.board?.project_id ? this.board?.project_id : this?.project?.id,
        );
        console.log("error info", this.labels);
      } catch (e) {
        console.log(e.message);
      }
    },
    onCancel() {
      if (this.type === "edit") {
        this.$emit("close", "edit");
      } else {
        this.$emit(
          "close",
          "create",
          this.labelsList.filter((item) => item.is_assigned == true)
        );
      }
    },
    onCreate() {
      this.dialogType = "create";
      this.visibleDialog = true;
      this.selectedLabel = {};
    },
    onEdit(item) {
      this.visibleDialog = true;
      this.dialogType = "edit";
      this.selectedLabel = item;
    },
    async createLabel(label) {
      try {
        const createdLabel = await projectsService.addLabel(
          this.projectId || this.project.id,
          label
        );
        this.labelsList?.push(createdLabel);
      } catch (e) {
        console.log(e.message);
      }
    },
    async updateLabel(label) {
      try {
        const updatedLabel = await projectsService.updateLabel(
          this.copiedUuid,
          label.id,
          label
        );
        const searchItem = this.labelsList.findIndex(
          (item) => item.id === label.id
        );
        Vue.set(this.labelsList, searchItem, updatedLabel);
      } catch (e) {
        console.log(e.message);
      }
    },
    async deleteLabel(label) {
      try {
        await projectsService.deleteLabel(
          this?.project?.id || this?.projectId,
          label.id,
          label
        );
        const searchItem = this.labelsList?.findIndex(
          (item) => item.id === label.id
        );
        this.labelsList?.splice(searchItem, 1);
      } catch (e) {
        console.log(e.message);
      }
    },
    toggleStatus(label, isCheckbox = true) {
      if (isCheckbox === false) {
        this.$set(label, "is_assigned", !label.is_assigned);
      }
      if (this.type === "edit") {
        if (label.is_assigned) {
          this.$emit("addLabelToCard", label, this.task);
        } else {
          this.$emit("deleteLabelFromCard", label, this.task);
        }
      }
      if(this.location === 'testCase') {
        if (label.is_assigned) {
          this.$emit("addLabelToTestCase", label);
        } else {
          this.$emit("deleteLabelFromTestCase", label);
        }
      }
    },
  },
  computed: {
    ...mapGetters("sideBarValues", {
      copiedUuid: getterTypes.GET_COPIED_UUID,
      copiedUuidBoard: getterTypes.GET_COPIED_UUID_BOARD,
    }),
  },
};
</script>
