// function notEngTxt(constant) {
//   return `The field "${constant}" must be Cyrillic!`;
// }
// function isRequired(constant) {
//   return `Field "${constant}" is mandatory!`;
// }
import menu from "./menu.json";
import home from "./home.json";
import table from "./table.json";
import confirmDialog from "./confirm-dialog.json";
import boards from "./boards.json";
import auth from "./auth.json";
import profile from "./profile.json";
import error from "./error.json";
import testCases from "./test-cases.json";
export default {
  menu: { ...menu },
  home: { ...home },
  table: { ...table },
  confirmDialog: { ...confirmDialog },
  boards: { ...boards },
  auth: { ...auth },
  profile: { ...profile },
  error: { ...error },
  testCases: { ...testCases },
  menuLocal: {
    home: "Home",
    boards: "Boards",
  },
  btn: {
    settings: "Settings",
    translate: "Language",
    logout: "Logout",
    add: "Add",
    delete: "Delete",
    update: "Update",
    create: "Create",
    close: "Close",
    save: "Save",
    back: "Back",
    cancel: "Cancel",
    apply: "Apply",
    confirm: "Confirm",
    edit: "Edit",
    all: "All",
    archiveRestore: "Restore",
    addTime: "Add time",
    selectTime: "Select Time",
    HintTestCases: "Test cases",
    ActivityProjectDialog: "Activities",
    timeReport: "Time report",
  },
  form: {
    userisundefined: "The field must not be empty",
    email: "E-mail",
    password: "Password",
    name: "Name",
    surname: "Surname",
    phone_number: "Phone number",
    search: "Search",
    title: "Title",
    description: "Description",
    title_setting: "Settings",
    phone: "Phone number",
    Curpassword: "Current pass",
    NewPass: "New pass",
    Confirpassword: "Confirm pass",
    SendPass: "Send code again",
    CodeSuccess: "The code has been confirmed",
    PassNotMatch: "Passwords don't match",
    PassSendEmail: "Password recovery code sent to your email",
    NewPassSave: "New password saved!",
    ChangePhoto: "Change photo",
    Tasks: "Tasks",
    TotalTaskBtn: "Assigned tickets",
    BugText: "Bug",
    Profile: "Profile",
    username: "Username",
    nickname: "Nickname",
    deluserboard: "User successfully deleted",
    adduserboard: "User successfully added",
    cardname: "Card name: ",
    testcasetitle: "Test cases",
    Project_lable: "Projects",
    ProjectSearch: "No projects found",
    StanDate: "Status duration",
    TypeTask: "Type task",
    parentTaskLableTrue: "Those that are a parent",
    parentTaskLableFalse: "Those that are not a parent",
    parentTaskLableNull: "All",
    timeError: "The specified date has not yet arrived.",
    projectName: "Project name",
    leaveTitle: "Leave the project",
    leaveDescription: "If you leave the project, you will lose all data related to this project.",
    AllYourTasks: "All your tasks",
    DataTimeDays: "[\"Sun\", \"Mon\", \"Tue\", \"Wed\", \"Thu\", \"Fri\", \"Sat\"]",
    BlockUserTimeTrue: "Time recording is unlocked",
    BlockUserTimeFalse: "Time recording is blocked",
    TotalProgressParentTask: "Total progress",
    OwnerTitle: "Project owner",
    MoveTaskTitle: "Move tasks to another list",
    MoveTasklists: "Lists",
    SelectTaskMove: "Select task!",
    taskDescriptionDialog: {
      users: "Users",
      tags: "Tags",
      tagsSearch: "Search tag",
      NameTag: "Name tag",
      SaveTag: "Save tag",
      CreateNewTag: "Create new tag",
      backTag: "Back to tags",
      TimeTextTask: "Time task hr/min",
      messages: "Messages",
      finish_date: "Finish",
      start_date: "Start date",
      date: "Date",
      Description: "Description",
      Watch: "Watch",
      Subtasks: "Subtasks",
      addsubtasks: "Add subtask",
      addfile: "Add file",
      Additions: "Additions",
      Attachments: "Attachments",
      Activity: "Time report",
      ShowDetails: "Show details",
      HideDetails: "Hide details",
      PostComment: "Post a comment",
      status: "Status",
      story_points: "Difficulty rating",
      Selectedimagesucces: "Cover successfully updated",
      made_cover_image: "Make a cover",
      attached: "attached at",
      archive_title: "Archive",
      archive_desc: "Do you really want to archive task?",
      testCases: "Test cases",
      openList: "Open list",  
      SelectTestCaseTitle: "Selected test cases",
      duration: "Duration (Days)",
      inputNameTask: "Enter task name",
      selectboard: "Select board",
      ParentCardsInfo: "Project Map",
      ParentCard: "Parent card",
      ParentCards: "Parent cards",
      time_activity: "Activity:",
      time_task: "Task:",
      time: "Time:",
      time_other: "other",
      date_created: "Сreation date",
      CreateMulti: {
        lable_text: "Create a task",
        comment_text_one: "If you want, we can create a card for each new line",
        comment_text_two: "You can also create a single card with a long name.",
        btn_create_multi : "Create a task",
        btn_create_one: "Only one task",
      },
    },
    ParentTasks: {
      ProgressLable: "Edit progress",
      ProgressTable: "Progress",
    },
    History: {
      Page: "Page",
    },
    ConfirmParentTasks: {
      header: "Change task status",
      description_one: "Do you really want to change the task status to Done?",
      description_two: "If the status changes, this task will not appear in this list.",
    },
    deleteCardDialog: {
      DelBoard: "Delete board",
      Confirm: "Confirm the deletion",
      Textconfirm: "To confirm deletion, enter the name of the board:",
      Text: "Name board",
      error: "Name entered incorrectly",
      delete_dialog_title: "Delete",
      delete_dialog_desc: "Do you want to delete it?",
    },
    archiveDialog: {
      archiveTitle: "Archive",
      archive_nameTask: "Ticket",
      archive_dataDelete: "Date of deletion"
    },
    errors: {
      nameRequired: "Name is required",
      surnameRequired: "Surname is required",
      timeRequired: "Time field is required",
      emailRequired: "Email is required",
      emailMustBeValid: "Email must be valid",
      emailalreadyExists: "This email already exists.",
      nicknamereg: "Nickname is required",
      passwordRequired: "Password is required",
      passwordMustBeLike:
        "Choose a stronger password. Try a combination of letters, numbers, and symbols",
      PhoneNumberRequired: "Phone number is required",
      PhoneNumberMustBeLike:
        "The phone number should include: +(380)-000-000-000",
      photoMaxSize: "The photo must be less than 8 MB!",
      listAndNameRequired: "List and Name are required",
      registerUsername: "The username has already been taken.",
      Selectedimageerror: "Selected file is not an image",
      loginError: "Your login info is not correct",
      required: "Field is required!",
    },
    checklistDialog: {
      addTestCase: "Add test case",
      taskList: "Task list",
    },
    noData: "No data!",
    time: "Time",
    generally: "Overall",
    total: "Total:",
    totall: "Total",
    statisticBoard: "Statistic board",
    statisticLable: "Statistic",
    statisticTask : "Total Tasks",
    statisticParent: "Parent Tasks",
    statisticBug: "Bug Count",
    statisticCount: "Task Count", 
    statisticStory: "Story Points",
    statisticExportLable: "Export",
    statisticSuccess: "Data has been successfully exported",
    testCases: {
      selectTestCase: "Select Test Case",
      addTestCase: "Add Test Case",
      addTestSuite: "Add Test Suite",
      edit: "Edit",
      SelectStatus: "Select Status",
      createTestSuite: "Create Test Suite",
      editTestSuite: "Edit Test Suite",
      willBeCreatedIn: "Will be created in",
      createTestCase: "Create test case",
      editTestCase:" Edit test case",
      createdin: "It will be created in"
    },
    day_singular: "day",
    day_few: "days",
    day_many:'days',
    DeviceDialog: {
      deviceTitle: "Devices",
      NoDevices: "No devices found",
      AllDevices: "All devices",
      MyDevices: "My devices",
      addDevice: "Add device",
      repeatDevice: "Edit device",
      createDevice: "Create device",
      nameDevice: "Device name",
      modelDevice: "Device model",
      OwnerDevice: "Device owner",
      deviceProjectName: "Project",
      commentDevice: "Comment",
      blockDevice: "Block device",
      unblockDevice: "Unblock device"
    },
  },
};
