<template>
  <div>
    <v-dialog
      v-model="visibility"
      max-width="620"
      content-class="cross__dialog"
      :transition="$dialogTransition"
      @click:outside="onCancel()"
      scrollable
    >
      <v-card>
        <v-slide-x-transition>
          <div class="cross__inner">
            <v-btn fab small elevation="0" @click="onCancel()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-slide-x-transition>

        <v-data-table
          :items="TotalTaskList"
          :headers="header"
          mobile-breakpoint
          fixed-header
          :no-data-text="$t('table.noData')"
          hide-default-footer
          style="max-height: 400px; padding-bottom: 1px; overflow-y: auto;"
          dense
          :items-per-page="TotalTaskList.length"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t("form.AllYourTasks") }}</v-toolbar-title>
            </v-toolbar>
          </template>
        
          <template v-slot:item.name="{ item }">
            <span @click="SelectTask(item)" style="cursor: pointer; text-decoration: underline;">
              <!-- <span>{{ item.name.length > 20 ? item.name.substring(0, 20) + '...' : item.name }}</span> -->
               <span style="overflow: hidden; max-width: 335px; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2;">{{ item.name }}</span>
            </span>
          </template>
        
          <template v-slot:item.project="{ item }">
            <div class="d-flex align-center">
              <v-avatar v-if="item.project_media" size="27">
                <v-img :src="item.project_media" alt="project image" />
              </v-avatar>
              <v-avatar v-else size="27" class="orange text--lighten-1" style="font-weight: 700; cursor: default;">{{ getLetters(item.project_name) }}</v-avatar>
              <!-- <span class="ml-2">{{ item.project_name.length > 10 ? item.project_name.substring(0, 10) + '...' : item.project_name }}</span> -->
              <span class="ml-2 my-2" style="overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1;">{{ item.project_name }}</span>
            </div>
          </template>

          <template v-slot:footer>
            <div 
              v-if="!isLoader_pear_page"
              v-observer="{ nextPage: loadMoreTasks }"
              class="observer"
            ></div>
          </template>

        </v-data-table>

        <taskDescriptionDialog
          v-if="taskDialogVisible"
          :visible="taskDialogVisible"
          @close="taskDialogVisible = false"
          :type="'edit'"
          :task="card"
          :board="boards"
          :lists="lists"
          :users="users"
          :labels="labels"
          @UpdateTaskTotal="UpdateTotalTask"
          @ArchiveTaskTotal="ArchiveTotalTask"
          :totalTask="'true'"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import notifications from "@/mixins/notifications";
import cardsService from "@/services/request/cards/cardsService";
import dialogMixin from "@/mixins/dialogMixin";
import projectsService from "@/services/request/projects/projectsService";
import { mapGetters } from "vuex";
import taskDescriptionDialog from "../taskDescriptionDialog.vue";
import usersService from "@/services/request/users/usersService";
import _ from "lodash";

export default {
  data() {
    return {
      TotalTaskList: [],
      SelectTaskParams: [],
      taskDialogVisible: false,
      isLoader_pear_page: false,
      TotalPage: 1,

      attachments: [],
      boards: [],
      lists: [],
      labels: [],
      card: [],
      details: {},
      parent_cards: {},
      users: {},

      header: [
        { text: this.$t("form.title"), value: "name", width: "200px", sortable: false },
        { text: this.$t("form.Project_lable"), value: "project", width: "150px", sortable: false },
      ],
    };
  },

  components: { taskDescriptionDialog },
  mixins: [notifications, dialogMixin],
  async mounted() {
    this.GetTotalTask();
  },
  methods: {

    async UpdateTotalTask(value) {
      const index = this.TotalTaskList.findIndex(task => task.id === value.id);
      if (index !== -1) {
        this.$set(this.TotalTaskList, index, { ...this.TotalTaskList[index], ...value });
      }
    },

    async ArchiveTotalTask(value) {
      const index = this.TotalTaskList.findIndex(task => task.id === value);
      if (index !== -1) { this.TotalTaskList.splice(index, 1); }
    },

    loadMoreTasks() {
      if (!this.isLoader_pear_page) {
        this.fetchTaskUser();
      }
    },
    async SelectTask(task) {
      const GetSelectTask = await projectsService.getTaskTotalUser(task.project_id, task.list_id, task.id);

      this.card = GetSelectTask.card;
      
      const BoardId = GetSelectTask.boards.filter(
        newTask => newTask.id === this.card.board_id
      );
      
      const myList = BoardId[0].lists;
      
      this.boards = BoardId[0];
      this.lists = myList;
      this.labels = GetSelectTask.labels;
      this.attachments = GetSelectTask.attachments;
      this.users = GetSelectTask.users;
      //this.users = GetSelectTask.card.assigned_users;
      
      // console.log("task :", task);
      // console.log("BoardId :", BoardId);
      //console.log("GetSelectTask.card.list_id :", GetSelectTask.card.list_id);
      //console.log("LLLIIIISSTTTSSS :", myList);
      // console.log("this.card :", this.card);
      //console.log("this.boards :", this.boards);
      //console.log("this.lists :", this.lists);
      // console.log("this.users :", this.users);
      // console.log("GetSelectTask :", GetSelectTask);

      this.SelectTaskParams = GetSelectTask;
      this.taskDialogVisible = true;
    },
    async fetchTaskUser() {
      this.TotalPage++;
      this.GetTotalTask(this.TotalPage);
    },
    async GetTotalTask(page) {
      if (this.isLoader_pear_page) return;
      this.setLoading(true);
      try {
        let params = { page, per_page: 15 };
        const getUserTotal = await usersService.getUserCards(params);

        const newTasks = getUserTotal.data.filter(
          newTask => !this.TotalTaskList.some(existingTask => existingTask.id === newTask.id)
        );

        this.TotalTaskList = [...this.TotalTaskList, ...newTasks];
        if (getUserTotal.data.length < 15) {
          this.setLoading(true);
        } else { this.setLoading(false); }

      } catch (error) {
        console.error(error);
      }
    },
    getLetters(item) {
      let letters = "";
      if (item) {
        letters = item?.trim().charAt(0).toUpperCase();
      }
      return letters;
    },
    setLoading(value) {
      this.isLoader_pear_page = value;
    },
    onCancel() {
      this.$emit("close");
    },
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
  },
  watch: {
    GetTotalTask() {
        this.initializeObserver();
      },
    },

    beforeDestroy() {
      if (this.observer) {
        this.observer.disconnect();
      }
  },
};
</script>

<style lang="scss">
@import "../boardTable";
</style>
